import React from "react";
import styled from "styled-components";
import { color, space, typography, shadow } from "styled-system";
import { device } from "../../utils";

const Paragraph = styled.p`
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.66px;
  line-height: 26px;

  @media ${device.sm} {
    font-size: 20px;
    line-height: 32px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const ParagraphSmall = styled(Paragraph)`
  font-size: 14px;
  letter-spacing: -0.5px;
  line-height: 24px;

  @media ${device.sm} {
    font-size: 16px;
    line-height: 28px;
  }

  ${color};
  ${space};
  ${typography};
  ${shadow};
`;

const Text = ({ variant, ...props }) => {
  let TextRender;

  switch (variant) {
    case "small":
      TextRender = ParagraphSmall;
      break;
    default:
      TextRender = Paragraph;
  }

  return <TextRender color="text" {...props} />;
};

export default Text;
