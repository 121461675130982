exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invite-invite-code-js": () => import("./../../../src/pages/invite/[inviteCode].js" /* webpackChunkName: "component---src-pages-invite-invite-code-js" */),
  "component---src-pages-oauth-page-js": () => import("./../../../src/pages/oauth-page.js" /* webpackChunkName: "component---src-pages-oauth-page-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-why-allo-js": () => import("./../../../src/pages/why-allo.js" /* webpackChunkName: "component---src-pages-why-allo-js" */),
  "component---src-templates-promo-js": () => import("./../../../src/templates/promo.js" /* webpackChunkName: "component---src-templates-promo-js" */)
}

