import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";

import styled, { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";

import Header from "../Header";
import Footer from "../Footer";

import GlobalContext from "../../context/GlobalContext";

import GlobalStyle from "../../utils/globalStyle";

import imgSocialPreview from "../../assets/image/png/social-preview.png";

import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";
import "../../assets/fonts/typography-font/Circular-Std-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-Black.ttf";
import "../../assets/fonts/typography-font/CircularStd-BlackItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Bold.ttf";
import "../../assets/fonts/typography-font/CircularStd-BoldItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-BookItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Medium.ttf";
import "../../assets/fonts/typography-font/CircularStd-MediumItalic.ttf";

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "./bootstrap-custom.scss";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/typography-font/typo.css";
import "../../assets/fonts/fontawesome-5/css/all.css";

import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../../utils";
import { HelmetTitle } from "../HelmetTitle";

// TODO: Move this somewhere else.
const SITE_URL = "https://allo.finance";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = mode =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const Layout = ({ children, pageContext }) => {
  const gContext = useContext(GlobalContext);

  const [visibleLoader, setVisibleLoader] = useState(true);

  useLayoutEffect(() => {
    AOS.init();
    setVisibleLoader(false);
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function (event) {
        // The popstate event is fired each time when the current history entry changes.

        gContext.closeOffCanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function (event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeOffCanvas();
      },
      false
    );
  }, [gContext]);

  const isBare = pageContext.layout === "bare";

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <HelmetTitle title="Allo — The Mindful Money Practice" />
        <Helmet>
          {/* Primary tags  */}
          <meta
            name="description"
            content="Transform your relationship with money, and spend and save on the things that matter."
          />
          <link rel="icon" type="image/png" href="/favicon.png" />

          {/* Apple */}
          <meta name="apple-itunes-app" content="app-id=1505368956" />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={SITE_URL} />
          <meta
            property="og:title"
            content="Allo — The Mindful Money Practice"
          />
          <meta
            property="og:description"
            content="Spend and save on the things that matter."
          />
          <meta property="og:image" content={SITE_URL + imgSocialPreview} />
          <meta
            name="facebook-domain-verification"
            content="26h6n729g00hkriow7x62x4hvj3dda"
          />

          {/* Twitter */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:title"
            content="Allo — The Mindful Money Practice"
          />
          <meta property="twitter:url" content={SITE_URL} />
          <meta
            property="twitter:description"
            content="Spend and save on the things that matter."
          />
          <meta
            property="twitter:image"
            content={SITE_URL + imgSocialPreview}
          />
        </Helmet>
        <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          {!isBare && <Header isDark={gContext.headerDark} />}
          {children}
          {!isBare && <Footer isDark={gContext.footerDark} />}
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
