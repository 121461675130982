import React from "react";
import { Helmet } from "react-helmet";

export const HelmetTitle = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
    </Helmet>
  );
};
